/* General Dark Theme Settings */
body {
  background-color: #121212;
  color: #e0e0e0;
  font-family: 'Arial', sans-serif;
  margin: 0;
  padding: 0;
}

.app-container {
  max-width: 960px;
  margin: 0 auto;
  padding: 20px;
  text-align: center;
  background-color: #1e1e1e;
  border-radius: 10px;
  box-shadow: 0 0 15px rgba(255, 255, 255, 0.1);
}

.main-title {
  font-size: 2.5rem;
  color: #ffffff;
  margin-bottom: 20px;
}

.button-container {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-bottom: 30px;
}

.nav-button {
  background-color: #2b2b2b;
  color: #e0e0e0;
  padding: 12px 24px;
  font-size: 1.2rem;
  border: 2px solid #3c3c3c;
  border-radius: 8px;
  text-decoration: none;
  transition: background-color 0.3s ease, color 0.3s ease, box-shadow 0.3s ease;
}

.nav-button:hover {
  background-color: #4e4e4e;
  color: #ffffff;
  box-shadow: 0 4px 12px rgba(255, 255, 255, 0.2);
}

.nav-button:active {
  transform: scale(0.98);
}

.content-container {
  padding: 20px;
  background-color: #1e1e1e;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(255, 255, 255, 0.1);
}

/* Media Queries for Responsiveness */
@media (max-width: 600px) {
  .nav-button {
    font-size: 1rem;
    padding: 10px 20px;
  }

  .main-title {
    font-size: 2rem;
  }
}
