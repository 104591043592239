body {
    background-color: #121212;
    color: #e0e0e0;
  }
  
  .container {
    max-width: 900px;
    margin: 0 auto;
    padding: 20px;
    font-family: 'Arial', sans-serif;
    background-color: #1e1e1e;
    border-radius: 10px;
    color: #e0e0e0;
  }
  
  .title {
    text-align: center;
    font-size: 2rem;
    margin-bottom: 20px;
    color: #ffffff;
  }
  
  .device-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  
  .device-card {
    background-color: #2b2b2b;
    padding: 15px;
    margin: 10px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(255, 255, 255, 0.1);
    flex: 1 1 calc(48% - 20px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  
  .device-info {
    font-size: 1rem;
    color: #e0e0e0;
  }
  
  .online {
    color: #00ff00;
  }
  
  .offline {
    color: #ff0000;
  }
  
  .timestamp {
    font-size: 0.9rem;
    color: #b0b0b0;
    margin-top: 10px;
  }
  
  .no-data {
    text-align: center;
    color: #b0b0b0;
  }
  